<template>
    <div>
        <div style="cursor: pointer" class="three-btns-one container mb-5">
            <span @click="$router.push('/')">{{ $t("Main") }} /</span>
            <span @click="$router.push('/categories/' + item?.categoriesId?._id)">
                {{ $getByLang(item?.categoriesId?.name) }} /
            </span>
            <span @click="$router.push('/products/' + item?.subCategoriesId?._id)">
                {{ $getByLang(item?.subCategoriesId?.name) }} /
            </span>
            <span>{{ $getByLang(item.name) }}</span>
        </div>

        <div class="mt-5 container">
            <div class="row">
                <div class="col-md-6 pr-2">
                    <div id="carouselExampleInterval" class="carousel slide p-1" data-bs-ride="carousel">
                        <!-- :style="'background-color: ' + $getBG()" -->
                        <div class="carousel-indicators">
                            <button v-for="(itm, index) of colorActiveList" :key="index" type="button"
                                data-bs-target="#carouselExampleInterval" :data-bs-slide-to="index"
                                :class="index == 0 ? 'active' : ''" :aria-label="itm._id" aria-current="true"></button>
                        </div>

                        <div class="carousel-inner carousel-inner-one single-product">
                            <div v-for="(itm, index) of colorActiveList" :key="index" class="carousel-item"
                                :class="index == 0 ? 'active' : ''" data-bs-interval="10000">
                                <a :href="'https://herehome.sa:7000/uploads/' + itm.name" target="_blank">
                                    <img :src="$baseUploadURL + itm.name" :alt="item.name" />
                                </a>
                                <!-- <p>{{itm}}</p> -->
                            </div>
                        </div>
                        <button class="carousel-control-prev custm-btn" type="button"
                            data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                class="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next custm-btn" type="button"
                            data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                                class="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                            <span class="visually-hidden">Next</span>
                        </button>
                        <div>
                            <div class="sold">
                                <div class="icon position-absolute">
                                    <img v-if="item.imLikedThis" src="/images/unlike.svg" class="like"
                                        @click="addLike(item)" :alt="$getByLang(item.name)" />
                                    <img v-else src="/images/like.svg" class="like" @click="addLike(item)"
                                        :alt="$getByLang(item.name)" />
                                </div>
                                <span class="position-absolute dddscsdc">{{ item.sellingAmount }} {{ $t("sold") }}
                                </span>

                                <span class="itemofferdbyvalue" v-if="offerId"
                                    style="top: 84px; background: #990000; padding: 8px 13px">
                                    {{ $t("rival") }}
                                    {{ offerValue }}%
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="details p-lg-5">
                        <div class="chair">
                            <h3>{{ $getByLang(item.name) }}</h3>
                            <span v-if="item.availableAmount > 0">
                                <b-icon icon="star-fill" class="icon"></b-icon>{{ item.rate }} ({{ item.rateAmount }} {{
                                    $t("evaluation") }})
                            </span>

                            <span class="unavailable" v-if="item.availableAmount == 0">{{
                                $t("This product is currently out of stock")
                                }}</span>
                        </div>
                        <div>
                            <span class="price" v-if="offerId">{{ Math.round(price - price * (offerValue / 100)) }} {{
                                $t("SAR") }}</span>
                            <label class="price" v-else>{{  Math.round(price) }} {{ $t("SAR") }}</label>

                            <del class="price" style="padding: 0px 10px; font-size: 16px; color: #a49d9d"
                                v-if="offerId">{{ Math.round(price) }} {{ $t("SAR") }}</del>
                        </div>

                        <h5 style="
                margin-top: 0;
                padding: 0px 10px;
                font-size: 16px;
                color: rgb(245 159 0);
              ">
                            {{ $t("availableAmount") }}
                        </h5>
                        <p class="para-details">
                            {{ item.availableAmount }}
                        </p>

                        <h5>{{ $t("details") }}</h5>
                        <p v-html="item.description" class="para-details"></p>

                        <h5>{{ $t("deliver from") }}</h5>
                        <p class="para-details">{{ item.deliverFromTime ? item.deliverFromTime : 0 }} {{ $t("days") }}

                        </p>

                        <h5>{{ $t("deliver to") }}</h5>
                        <p class="para-details">

                            {{ item.deliverToTime ? item.deliverToTime : 0 }} {{ $t("days") }}</p>

                        <div class="colors">
                            <h5>{{ $t("Colors") }}</h5>
                            <div class="content-color d-inline-block">
                                <div v-for="(itm, index) of item.colorsList" :key="index" class="blue color" :style="colorActive == itm.colorId._id
                                    ? 'border: 1px solid black'
                                    : ''
                                    " @click="
                                        colorActive = itm.colorId._id;
                                    colorActiveList = itm.colorsImage;
                                    ">
                                    <span :style="'border:solid 0.5px;background-color: ' +
                                        itm.colorId.value
                                        "></span>
                                </div>
                            </div>
                        </div>

                        <div class="size" v-if="item.availableAmount > 0">
                            <h5>{{ $t("the size") }}</h5>
                            <div class="">
                                <div v-for="(itm, index) of item.sizes" :key="index" @click="
                                    sizeActive = itm.sizeId._id;
                                sizeActiveObj = itm.sizeId;
                                price = itm.mainPrice;
                                " class="btn ml-2" :class="sizeActive == itm.sizeId._id ? 'size1' : 'size2'">
                                    <div style="display: -webkit-box">
                                        <p>
                                            ({{ itm.sizeId.width }} {{ $t("cm") }} {{ $t("width") }} )
                                        </p>
                                        <p>
                                            ({{ itm.sizeId.height }} {{ $t("cm") }}
                                            {{ $t("height") }} )
                                        </p>
                                        <p v-show="itm.sizeId.depth">
                                            ({{ itm.sizeId.depth }} {{ $t("cm") }} {{ $t("depth") }} )
                                        </p>
                                    </div>
                                    <!-- ( {{ itm.sizeId.width }} {{ $t("cm") }} {{$t("width")}} ) X ({{ itm.sizeId.height }} {{ $t("cm") }} {{$t("height")}}) X

                  ({{ itm.sizeId?itm.sizeId.depth:0 }} {{ $t("cm") }} {{$t("depth")}} )  -->
                                </div>
                            </div>
                        </div>

                        <div class="product-details" :class="item.availableAmount > 0
                            ? ' d-flex justify-content-between flex-wrap'
                            : ''
                            ">
                            <div v-if="item.vendorsId && item.vendorsId.showInDetails" class="content">
                                <b-icon icon="patch-check" class="star"></b-icon>
                                <span>{{ $t("the shop") }}</span>
                                <a @click="$router.push('/shops/' + item.vendorsId._id)" class="d-block">{{
                                    item.vendorsId ? item.vendorsId.name : "" }}</a>
                            </div>
                            <div class="content">
                                <b-icon icon="bounding-box" class="star"></b-icon>
                                <span>الأبعاد</span>
                                <div style="display: -webkit-box">
                                    <p>
                                        ({{ sizeActiveObj.width }} {{ $t("cm") }}
                                        {{ $t("width") }} )
                                    </p>
                                    <p>
                                        ({{ sizeActiveObj.height }} {{ $t("cm") }}
                                        {{ $t("height") }} )
                                    </p>
                                    <p v-show="sizeActiveObj.depth">
                                        ({{ sizeActiveObj.depth }} {{ $t("cm") }}
                                        {{ $t("depth") }} )
                                    </p>
                                </div>

                                <!-- <p>

                   ( {{ sizeActiveObj.width }} {{ $t("cm") }} {{$t("width")}} ) X ({{ sizeActiveObj.height }} {{ $t("cm") }} {{$t("height")}}) X

                  ( {{ sizeActiveObj?sizeActiveObj.depth:0 }} {{ $t("cm") }} {{$t("depth")}} ) 

                </p> -->
                            </div>
                            <div class="content">
                                <b-icon icon="stack" class="star"></b-icon>
                                <span> {{ $t("made of") }}</span>
                                <p>{{ $getByLang(item.materialsId.name) }}</p>
                            </div>
                        </div>
                        <div class="row quantity" v-if="item.availableAmount > 0">
                            <div class="col-md-6">
                                <div class="total1">
                                    <span class="d-inline-block mr-2">{{ $t("Quantity") }}:</span>
                                    <span class="plus-btn" :disabled="body.quantity >= item.availableAmount"
                                        @click="body.quantity++">
                                        <b-icon icon="plus-lg" class="plus"></b-icon>
                                    </span>
                                    <span class="mx-2">{{ body.quantity }}</span>
                                    <span class="dash-btn" @click="body.quantity > 1 ? body.quantity-- : ''">
                                        <b-icon icon="dash-lg" class="dash"></b-icon>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="total2">
                                    <span> {{ $t("Total") }}:</span>
                                    <span class="num" v-if="offerId">{{ Math.round((price - price * (offerValue / 100)) * body.quantity) }}
                                        {{ $t("SAR") }}</span>
                                    <span class="num" v-else>{{ Math.round(price * body.quantity) }} {{ $t("SAR")
                                        }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="add-to-card" v-if="item.availableAmount > 0">
                            <button @click="addToCart()" id="AddToCart">
                                <b-icon icon="cart3" class=""></b-icon>
                                {{ $t("Add to cart") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="rating mb-5 mt-5" v-if="item.availableAmount > 0">
            <div class="container">
                <div class="d-flex align-items-center mb-4">
                    <h5 class="mb-0">{{ $t("Ratings") }}</h5>
                    <button @click="openModal()">
                        <b-icon icon="plus-lg" class="plus"></b-icon>
                        {{ $t("add a comment") }}
                    </button>
                    <Dialog :visible.sync="displayModal" :containerStyle="{ width: '30vw' }" class="dialog-one"
                        :modal="true" :showHeader="false" :showFooter="false">
                        <div class="product-eval p-5">
                            <div class="send">
                                <b-icon icon="x" class="close" @click="displayModal = false"></b-icon>
                                <h5>{{ $t("Add your rating") }}</h5>
                            </div>

                            <form method="post" @submit.prevent="addrate()">
                                <div class="mb-4">
                                    <b-form-rating v-model="body2.rate" variant="warning" class="mb-2" inline no-border
                                        locale="ar-EG"></b-form-rating>
                                </div>
                                <div class="product-description mb-4">
                                    <p @click="body2.comment = $t('The product is great')">
                                        {{ $t("amazing") }}
                                    </p>
                                    <p @click="body2.comment = $t('The product is good')">
                                        {{ $t("The product is good") }}
                                    </p>
                                    <p @click="body2.comment = $t('The product has defects')">
                                        {{ $t("The product has defects") }}
                                    </p>
                                    <p @click="body2.comment = $t('The product is not useful')">
                                        {{ $t("The product is not useful") }}
                                    </p>
                                </div>
                                <textarea v-model="body2.comment" class="form-control" rows="4" cols="5"
                                    :placeholder="$t('Write a comment')" />
                                <button type="submit">{{ $t("save") }}</button>
                            </form>
                        </div>
                    </Dialog>
                </div>

                <div class="s70">
                    <div class="here-home" v-for="(itm, index) of list" :key="index">
                        <div class="comment">
                            <img :src="itm.userId?.image
                                ? $baseUploadURL + itm.userId?.image
                                : '/images/user.png'
                                " alt="" style="width: 50px" />
                            <div class="content mr-5">
                                <h6>{{ itm.userId?.userName }}</h6>
                                <p>{{ $durationFormatFrom(itm.createdAt) }}</p>
                            </div>
                        </div>
                        <div>
                            <b-form-rating v-model="itm.rate" variant="warning" class="mb-2" inline no-border readonly
                                locale="ar-EG"></b-form-rating>
                        </div>
                        <p>
                            {{ itm.comment }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "productDetails",
    head: {
        title: function () {
            return {
                inner: this.$getByLang(this.item.name),
            }

        },
    },
    data() {
        return {
            displayModal: false,
            sizeActive: null,
            price: null,
            sizeActiveObj: {
                width: 0,
                height: 0,
            },
            colorActive: null,
            colorActiveList: [],
            item: {
                name: {
                    ar: null,
                    en: null,
                },
                rate: 0,
                rateAmount: 0,
                sellingAmount: 0,
                description: null,
                availableAmount: null,
                vendorsId: {
                    name: null,
                },
                vandorAddress: null,
                installmentsId: {
                    name: {
                        ar: null,
                        en: null,
                    },
                    installmentImage: null,
                },
                categoriesId: {
                    name: {
                        ar: null,
                        en: null,
                    },
                    categoryImage: null,
                },
                subCategoriesId: {
                    name: {
                        ar: null,
                        en: null,
                    },
                },
                materialsId: {
                    name: {
                        ar: null,
                        en: null,
                    },
                },
                needToInstalled: true,
                deliverFromTime: null,
                deliverToTime: null,
                govId: {
                    name: {
                        ar: null,
                        en: null,
                    },
                },
                countryId: {
                    name: {
                        ar: null,
                        en: null,
                    },
                },
                cityId: {
                    name: {
                        ar: null,
                        en: null,
                    },
                },
                colorsList: [],
                sizes: [],
            },
            body: {
                quantity: 1,
            },
            body2: {
                rate: 0,
                itemId: 0,
                comment: null,
            },

            list: [],
            offerId: null,
            offerValue: 0,
        };
    },
    methods: {
        addLike(item) {
            if (localStorage.homeHere) {
                this.$http
                    .post("favourite", {
                        itemId: item._id,
                    })
                    .then((res) => {
                        item.imLikedThis = !item.imLikedThis;
                        this.$swal({
                            title: this.$t("Successfully Done"),
                            icon: "success",
                            timer: 3000,
                            showConfirmButton: false,
                        });
                    });
            } else {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t("Error"),
                    detail: "Must be loged in",
                    life: 3000,
                });
            }
        },
        addToCart() {
            if (localStorage.homeHere) {
                let obj = {
                    itemId: this.id,
                    quantity: this.body.quantity,
                    price: this.price,
                    sizeId: this.sizeActive,
                    colorId: this.colorActive,
                };

                if (this.offerId) {
                    obj.offerId = this.offerId;

                    const ofer = this.price * (this.offerValue / 100);
                    obj.price = this.price - ofer;
                    obj.priceBefforOffer = this.price;
                }
                this.$http.post("cart", obj).then(
                    (res) => {
                        this.$eventHub.$emit("updateCart", {});

                        this.$swal({
                            title: this.$t("Added Successfully"),
                            icon: "success",
                            timer: 3000,
                            showConfirmButton: false,
                        });
                    },
                    (err) => {
                        this.$toast.add({
                            severity: "error",
                            summary: this.$t("Error"),
                            detail: err.response.data.message,
                            life: 3000,
                        });
                    }
                );
            } else {
                this.$router.push("/login");
            }
        },
        openModal() {
            this.body2 = {
                rate: 0,
                itemId: this.id,
                comment: null,
            };
            if (localStorage.homeHere) {
                this.displayModal = true;
            } else {
                this.$router.push("/login");
            }
        },
        addrate() {
            if (this.body2.rate && this.body2.comment) {
                this.displayModal = false;

                this.$http.post("rate", this.body2).then(
                    (res) => {
                        this.getRates();
                        this.$swal({
                            title: this.$t("Successfully Done"),
                            icon: "success",
                            timer: 3000,
                            showConfirmButton: false,
                        });
                    },
                    (err) => {
                        this.$toast.add({
                            severity: "error",
                            summary: this.$t("Error"),
                            detail: err.response.data.message,
                            life: 3000,
                        });
                    }
                );
            } else {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t("Error"),
                    detail: this.$t("Please add the required fields"),
                    life: 3000,
                });
            }
        },
        getRates() {
            this.$http
                .post("rate/getRatesForItem?limit=100000", {
                    itemId: this.id,
                })
                .then((res) => {
                    this.list = res.data.docs;
                });
        },
    },
    mounted() {
        console.log("from mounted");
        setTimeout(() => {
            if (this.offerId) {
                let obj = {
                    offerId: this.offerId,
                    itemId: this.id,
                    sizeId: localStorage.getItem("sizeId")
                }
                console.log("555555555555", obj);

                this.$http.post("offers/getOfferItemDetailsById/", obj).then((res) => {
                    console.log(res.data);

                    this.item = res.data.itemId;
                    if (this.item.colorsList.length > 0) {
                        this.colorActive = this.item.colorsList[0].colorId._id;
                        this.colorActiveList = this.item.colorsList[0].colorsImage;
                        let uniqueArr = this.item.colorsList.filter(
                            (value, index, self) =>
                                index === self.findIndex((t) => t.colorId._id === value.colorId._id)
                        );
                        let intersection = this.item.colorsList.filter(
                            (x) => !uniqueArr.includes(x)
                        );
                        let arr = [];
                        for (const iterator of uniqueArr) {
                            for (const iterator1 of intersection) {
                                if (iterator.colorId._id == iterator1.colorId._id) {
                                    iterator.colorsImage = iterator.colorsImage.concat(
                                        iterator1.colorsImage
                                    );
                                }
                            }
                            arr.push(iterator);
                        }
                        this.item.colorsList = arr;
                    }
                    if (this.item.sizes.length > 0) {
                        this.sizeActive = res.data.sizeId._id;
                        this.sizeActiveObj = res.data.sizeId;
                        this.price = res.data.itemPrice;
                    }
                });

            }

        }, 700);
    },
    created() {
        console.log("from created");

        this.id = this.$route.params.id;


        this.$http.get("items/" + this.id).then((res) => {
            this.item = res.data;
            this.$emit('updateHead')

            if (this.item.colorsList.length > 0) {
                this.colorActive = this.item.colorsList[0].colorId._id;
                this.colorActiveList = this.item.colorsList[0].colorsImage;
                let uniqueArr = this.item.colorsList.filter(
                    (value, index, self) =>
                        index === self.findIndex((t) => t.colorId._id === value.colorId._id)
                );
                let intersection = this.item.colorsList.filter(
                    (x) => !uniqueArr.includes(x)
                );
                let arr = [];
                for (const iterator of uniqueArr) {
                    for (const iterator1 of intersection) {
                        if (iterator.colorId._id == iterator1.colorId._id) {
                            iterator.colorsImage = iterator.colorsImage.concat(
                                iterator1.colorsImage
                            );
                        }
                    }
                    arr.push(iterator);
                }
                this.item.colorsList = arr;
            }
            if (this.item.sizes.length > 0) {
                this.sizeActive = this.item.sizes[0].sizeId._id;
                this.sizeActiveObj = this.item.sizes[0].sizeId;
                this.price = this.item.sizes[0].mainPrice;
            }
        });
        this.getRates();
    },
    watch: {
        sizeActive(val) {
            if (val) {
                this.$http
                    .post("offers/GetIfOffer", {
                        "selectedItems.itemId": this.id,
                        "selectedItems.sizeId": val,
                        "selectedItems.colorId": this.colorActive,
                    })
                    .then((res) => {

                        if (res.data && res.data._id) {
                            let findItem = res.data.selectedItems.find(li => li.itemId == this.id && li.sizeId == val && li.colorId == this.colorActive)
                            this.offerId = res.data._id;
                            console.log("1111111111offer id111111111111111", findItem);

                            this.offerValue = findItem.value;
                        } else {
                            this.offerId = null;
                            this.offerValue = 0;
                        }
                    });
            }
        },
        colorActive(val) {
            if (val) {
                console.log(" inside color active ", val);
                console.log(" inside color active size active", this.sizeActive);

                this.$http
                    .post("offers/GetIfOffer", {
                        "selectedItems.itemId": this.id,
                        "selectedItems.sizeId": this.sizeActive,
                        "selectedItems.colorId": val,
                    })
                    .then((res) => {
                        console.log("222222222222222222222222", res.data);
                        if (res.data && res.data._id) {
                            this.offerId = res.data._id;
                            let findItem = res.data.selectedItems.find(li => li.itemId == this.id && li.sizeId == val && li.colorId == this.colorActive)

                            this.offerValue = findItem.value;
                        } else {
                            this.offerId = null;
                            this.offerValue = 0;
                        }
                    });
            }
        },
    },
};
</script>

<style></style>
